.root {
  @apply py-5 bg-grayLight;
}
.main {
  @apply transition-all duration-500 bg-grayLight border-b border-t border-borderGray z-[1] box-content overflow-hidden;
  /*max-h-[calc(78vh-100px)]*/
}

/*requirement for sticky scroll based filtered diamond page*/
:global(.sticky_filter) .main {
  @apply max-h-[calc(78vh-100px)] overflow-auto;
}
.main_wrp {
  @apply relative;
}
/* .isOpen ~  .filtersButton{
  @apply md:w-auto;
} */
.overlay {
  @apply hidden;
}
.isOpen + .filtersButton {
  @apply opacity-100;
}
.container {
  @apply flex flex-wrap max-w-site gap-y-[30px] mx-auto px-6 lg:px-3 justify-center;
}
.items {
  @apply flex-1 w-1/3 basis-1/3 px-3 ;
  @apply lg:w-1/2 lg:basis-1/2 md:w-full md:basis-auto md:sticky md:bottom-0 md:bg-grayLight md:z-[1] md:pt-3 max-w-[50%] md:mx-auto md:flex-none md:max-w-full;
}
.filtersButton {
  @apply m-0 bg-grayLight text-sm font-medium relative  border-borderGray hover:border-borderGray hover:opacity-100 border border-solid !border-t-transparent z-[1] px-3 gap-1;
}

.filtersButton_wrap{
  @apply -order-1 z-[2] w-full -top-[1px] md:-top-[1px] relative;
}
.button {
  @apply font-medium text-[0.938rem];
}
.filterResetButton {
  @apply bg-transparent absolute translate-x-[-30px] min-w-[unset] transition-[0.35s] duration-[ease] z-[-1] m-0 border-0 left-auto top-auto text-primary text-sm font-medium px-3;
  /* @apply; */
}
.filterResetButton > span {
  @apply whitespace-nowrap md:pt-0;
}
.filterResetButton:not(.activeReset) {
  @apply max-w-0 opacity-0 invisible overflow-hidden;
}
.activeReset {
  @apply translate-x-0 opacity-100 z-0 max-w-full visible;
}
.done{
  @apply w-full h-[44px] m-0 min-w-[120px];
}

/*Mobile Responsive*/
.main_wrp_mobile {
  @apply flex flex-wrap justify-center order-1 mt-auto sticky bottom-0 z-10 ;
  /*@apply fixed bottom-0 left-0 right-0 w-full;*/
  /*@apply flex-col;*/
  @apply bg-grayLight;
}
.main_wrp_mobile .main{
  @apply max-h-[calc(80vh-var(--stickyHeight))] border-b-0 w-full flex flex-col ;
  @apply md:max-h-[calc(95vh-var(--stickyHeight))];
  /* Extra added due to mobile issue in safari */
  @apply overflow-hidden;
}
.main_wrp_mobile .main:not(.isOpen){
  @apply border-none;
}
.main_wrp_mobile .root{
  @apply flex-1;
  /* Extra added due to mobile issue in safari */
  @apply h-full pb-[70px] pt-0 relative;
}
.main_wrp_mobile .container{
  @apply h-full;
  /* Extra added due to mobile issue in safari */
  @apply flex-nowrap flex-col justify-start sm:p-0 overflow-auto pb-3 w-full overflow-x-hidden pt-4 sm:pt-4;
  /* overflow-auto */
}
.main_wrp_mobile .filtersButton {
  @apply border-0;
  @apply md:w-auto;
}
.main_wrp_mobile .filtersButton > svg:last-of-type {
  rotate: 180deg;
}
.main_wrp_mobile:not(.isActive) .filtersButton{
  @apply bg-primary text-white;
}
.main_wrp_mobile .isOpen + .filtersButton_wrap{
  @apply md:top-[1.5px] border-b border-b-border;
}
.main_wrp_mobile .filtersButton_wrap{
  @apply border-t border-t-borderGray bg-grayLight md:top-0;
}
.main_wrp_mobile .isOpen .overlay {
  @apply bg-black/70 content-[""] fixed z-[-1] inset-0 block;
}
@media screen and (max-height: 550px) {
  .main_wrp_mobile .main{
    @apply max-h-[calc(70vh-var(--stickyHeight))]
  }
}
.loadingFilter {
  @apply h-[260px] bg-grayLight;
}
.filterLoading {
  @apply bg-grayLight h-[260px] md:h-[0px] ;
}
.items{
  @apply sm:-mx-0 py-3 max-w-none border-t border-borderDark/15 md:mt-[auto] absolute bottom-0 w-full;
}
/* .items{
  @apply inline-flex items-center justify-center flex-none p-2 rounded-full w-14 h-14 mx-auto;
  box-shadow: 0 0 10px rgba(0,0,0, 0.3);
}
.done {
  @apply min-w-[unset] w-10 h-10 text-[0] relative rounded-full before:content-[""] before:absolute before:-translate-x-2/4 before:-translate-y-2/4 before:-rotate-45 before:h-2 before:w-[18px] before:border-r-[none] before:border-t-[none] before:border-2 before:border-solid before:left-2/4 before:top-[45%] before:border-t-0 before:border-r-0;
}
.items {
  @apply md:bottom-[5px];
} */
